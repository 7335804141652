import React, {useState, useEffect, useRef} from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputLabel from '@mui/material/InputLabel';
import { getAllpose, getnextpose, getAllchoices } from '../apis/api';
import Box from '@mui/material/Box';
import FrameViewer from './capture_frame.js';
import { Button, useForkRef} from '@mui/material';
import { all_pose_image } from './img_pose_config.js';
import { ImageViewer } from './capture_frame.js';
import PopupModel from './PosePredModel.js';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import GenerateClass from './generateClass.js';
import { readFromLocalStorage, writeToLocalStorage } from '../apis/helperfunctions.js';
import CircularTimer from './timer.js';
import {Grid} from '@mui/material';
import SpeedAngle from './speedangle.js';



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function taballyProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}






const drawerWidth = 300;

const Sidebar = styled('div')(({ theme }) => ({
  display: 'flex',
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    opacity: 0.98,
  },
}));

const DrawerContainer = styled('div')(({ theme }) => ({
  overflow: 'auto',
  padding: theme.spacing(2),
  width: drawerWidth,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const Content = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));


const ToggleButtonContainer = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('lg')]: {
    display: 'block',
    position: 'fixed',
    top: theme.spacing(1),
    left: theme.spacing(1),
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const SideBar = ({allPose, currentPose , setcurrentPose, tabvalue, settabvalue,class_seq, setclass_seq, allchoices})=>{
  const [open, setOpen] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [exType, set_exType] = useState('Yoga')
  const [exercise, setexercise] = useState([])
  // const [tabvalue, settabvalue] = useState(0) 
  const [gennew_class, setgennew_class] = useState(false)
  // const [class_seq, setclass_seq] = useState([])
  const [switcher, setswitcher] = useState(false)

  const tabhandleChange= (e, newvalue)=>{
    settabvalue(newvalue)
    writeToLocalStorage("tabvalue", newvalue)
  }


  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  const ex_type_handler = (e) => {
    const selectedType = e.target.value;
    
    set_exType(selectedType);
      
    if (selectedType === 'Yoga') {
      setexercise(allPose.yoga_posename);
 
    } else {
      setexercise(allPose.normal_posename);
    }
    setcurrentPose(exercise[0])
 
  
  };

  const closegeneration = (s)=>{
    console.log(s, 'close generations')
    setgennew_class(false)
    setswitcher(prev => !prev)
  }
  
  const currentPosehandler = (e)=>{
    setcurrentPose(e.target.value)
  }
  
  useEffect(()=>{
      if (allPose.yoga_posename){
        setexercise(allPose.yoga_posename)
      }
      
  }, [allPose])


  useEffect(() => {
    if (tabvalue === 1 && exercise && exercise.length > 0) {
      setcurrentPose(exercise[0]);
    }
    if (tabvalue === 0 && class_seq && class_seq.length > 0){
      setcurrentPose(class_seq[0])
    }
    
  }, [exercise,  tabvalue, class_seq]);

  useEffect(() =>{
       const cls_seq = readFromLocalStorage('class_sequences')
       if (cls_seq){
        setclass_seq(["Tadasana (Mountain Pose)", ...cls_seq])
       }
       

  }, [gennew_class, switcher])


  return (
    <Sidebar>
      {isSmallScreen && (
        <ToggleButtonContainer>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </ToggleButtonContainer>
      )}
      <StyledDrawer
        variant={isSmallScreen ? 'temporary' : 'permanent'}
        open={isSmallScreen ? open : true}
        onClose={handleCloseDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Toolbar>
          <Typography variant="subtitle1" component={'div'} sx={{marginLeft:5}} align="center" noWrap>
            Pose Settings
          </Typography>
        </Toolbar>
        <DrawerContainer>

        <Tabs
        value={tabvalue}
        onChange={tabhandleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab label="Sequnce Class"  {...taballyProps(0)}/>
        <Tab label="Random" {...taballyProps(1)}/>
      </Tabs>
      <CustomTabPanel value={tabvalue} index={0}>
      <Box sx={{ marginBottom: 2 }}>
              <InputLabel htmlFor="type_exercise">Class Exercises</InputLabel>
              <Select
                fullWidth
                labelId="exercise_label"
                id="exercise"
                label="Exercises"
                value={currentPose}
                onChange={currentPosehandler}
              >

                {class_seq && class_seq.length > 0 && (
                  class_seq.map((val, index)=>{
                    return <MenuItem key={index} value={val}>{val}</MenuItem>
                })
                )}
              </Select>
            </Box>
          
            {class_seq.length < 1  || gennew_class ? (
                <GenerateClass allchoices={allchoices}  closegeneration = {closegeneration}/>
              ) : null
            }
          
          <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: 'white',
                color: 'green',
                marginTop: '10px',
                marginBottom: '10px'
              }}
              onClick={() => setgennew_class(prev => !prev)}
            >
              {gennew_class ?  "Close New Generation" : "Generate New"}
            </Button>
      </CustomTabPanel>

      
      
      <CustomTabPanel value={tabvalue} index={1}>
      <div>
            <Box sx={{ marginBottom: 2 }}>
              <InputLabel htmlFor="type_exercise">Exercise Type</InputLabel>
              <Select
                fullWidth
                labelId="type_exercise_label"
                id="type_exercise"
                label="Exercise Type"
                value={exType}
                onChange={ex_type_handler}
              >
                <MenuItem value={"Yoga"}>Yoga</MenuItem>
                <MenuItem value={"Normal"}>Normal</MenuItem>
              </Select>
            </Box>

            
            <Box sx={{ marginBottom: 2 }}>
              <InputLabel htmlFor="type_exercise">Exercises</InputLabel>
              <Select
                fullWidth
                labelId="exercise_label"
                id="exercise"
                label="Exercises"
                value={currentPose}
                onChange={currentPosehandler}
              >

                { exercise && (
                  exercise.map((val, index)=>{
                    return <MenuItem key={index} value={val}>{val}</MenuItem>
                })
                )}
              </Select>
            </Box>
          </div>
      </CustomTabPanel>



        
          {isSmallScreen && (
            <IconButton
              color="inherit"
              aria-label="close drawer"
              onClick={handleCloseDrawer}
              sx={{ alignSelf: 'flex-end' }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DrawerContainer>
      </StyledDrawer>
    </Sidebar>
      
  )
}


const write_ht_px_ratio = (ratio)=>{
  writeToLocalStorage('ht_px_ratio', ratio)
  writeToLocalStorage('ht_px_ratio_exp', Math.floor(Date.now() / (1000 * 60)))
}



const SidebarComponent = () => {
  const [currentPose, setcurrentPose]= useState("")
  const [allpose, setallpose] = useState({})
  const [allchoices, setallchoices] = useState({})
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const ws = useRef(null);
  const [responseFrame, setResponseFrame] = useState(null);
  const isStart = useRef(true)
  const [isPermission, setPermission] = useState(false)
  const [goalType, set_goalType] = useState('normal')
  const [nextposes, setnextposes] = useState([null, null])
  const [tabvalue, settabvalue] = useState(0)
  const [class_seq, setclass_seq] = useState([])
  const [targettimer, settargettimer] = useState(0)
  const count = useRef(0)
  const backendWsUrl = process.env.REACT_APP_BACKEND_WS_URL;
  const height = useRef(0)
  const is_cal_ratio  = useRef(false)
  const ht_px_ratio = useRef(0)
  const [speed, setspeed] = useState({})
  const [angle, setangle] = useState({})

  const allposeupdater = (data)=>{
    setallpose(data)
  }

  useEffect(()=>{
       getAllpose().then(data=>{
        if(!data){
          return
        }
        if (data.status === 200){
          setallpose(data.data)
          // setnextposes([data.data.normal_posename[0],data.data.normal_posename[1]])
          return
        }
        console.log(data)

       })

       getAllchoices().then(data =>{
        if (!data){
          return 
        }
        if (data.status === 200){
          setallchoices(data.data)
          return
        }
        console.log(data)
       })

  }, [])

  useEffect(() => {
    // Initialize camera stream
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            setPermission(false)
          }
        })
        .catch(error => {
          console.error('Error accessing camera:', error)
          setPermission(true)
        });
    }
  
  }, []);

  const isStartHandler = (val)=> {
    isStart.current = val
  };

  const next_Pose_handler = (e)=>{
    e.preventDefault()
    closeConnection()
    getnextpose({'focus': goalType, 'pose_name': currentPose}).then((data)=>{
      if(!data){
        return
      }
      if(data.status=== 200){
        setnextposes(data.data.next_poses)
        // console.log(data.data.next_poses)
        return
      }
      console.log(data)
    })
  }

  const changeCurrentPose = (idx)=>{
    // console.log('Tab value', tabvalue, class_seq.length)
    let tabvales = readFromLocalStorage('tabvalue')
    if (tabvalue === 0 && class_seq &&  class_seq.length > 0 && !tabvales){
    idx = idx % class_seq.length
    console.log("index", idx)
    setcurrentPose(class_seq[idx])
    }
  }




  useEffect(()=>{
    
    let tabvales = readFromLocalStorage('tabvalue')
    if (tabvalue  === 1 && Number(tabvales) === 1){
      isStartHandler(false)
      console.log('tab value is 1', isStart.current)
    }else{
      isStartHandler(true)
      console.log('tab value is 0', isStart.current)
    }
    
  }, [tabvalue])


  useEffect(() => {
    // Open WebSocket connection
    const connect_websocket = ()=>{
      if (!isStart.current){
      closeConnection()
    }

    console.log('Current Pose when at start', currentPose)
    
    ws.current = new WebSocket(`${backendWsUrl}/${currentPose}/`);
    
    ws.current.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.current.onmessage = (event) => {
      
      // Handle response frame from server
      const receivedData = JSON.parse(event.data); // Parse the JSON data from server
      const receivedFrame = receivedData.frame;

      

      
      if (!receivedFrame){
         connect_websocket()
         console.log('web socket conneting again')
         return 
      } // Extract the frame from received data
      
      if (receivedData.ht_px_ratio   > 0){
        // console.log(receivedData)
        write_ht_px_ratio(receivedData.ht_px_ratio)
        is_cal_ratio.current = false
      }

      if(receivedData.angles){
        setangle(receivedData.angles)
      }
      if(receivedData.speed){
        setspeed(receivedData.speed)
      }

      setResponseFrame(receivedFrame);
      
    };
    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
    
    }
    connect_websocket()
    
    
    return () => {
      // Clean up WebSocket connection
      ws.current.close();
    };
  }, [isStart.current]);
  
  useEffect(()=>{
     closeConnection()
    //  isStart.current = false
    const connect_websocket = ()=>{
      if (!isStart.current){
      closeConnection()
    }
    ws.current = new WebSocket(`${backendWsUrl}/${currentPose}/`);
    
    ws.current.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.current.onmessage = (event) => {
      
      // Handle response frame from server
      const receivedData = JSON.parse(event.data); // Parse the JSON data from server
      const receivedFrame = receivedData.frame;
      

      
      if (!receivedFrame){
         connect_websocket()
         console.log('web socket conneting again')
         return 
      } // Extract the frame from received data

      if (receivedData.ht_px_ratio   > 0){
        // console.log(receivedData)
        
        write_ht_px_ratio(receivedData.ht_px_ratio)
        ht_px_ratio.current = receivedData.ht_px_ratio
        is_cal_ratio.current = false
      }

      if(receivedData.angles){
        setangle(receivedData.angles)
      }
      if(receivedData.speed){
        setspeed(receivedData.speed)
      }
      setResponseFrame(receivedFrame);
      
    };
    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
    
    ws.current.onclose = (event) => {
      if (!event.wasClean) {
        if (currentPose && tabvalue === 0){
          console.log('WebSocket closed unexpectedly, reconnecting...');
          setTimeout(() => connect_websocket(), 1000);
        
        }
      }
    };
  }
    connect_websocket()

    let h = readFromLocalStorage('height')

    if(h != null){
      height.current = Number(h)
    }
    let ht_px_ratio_s = readFromLocalStorage('ht_px_ratio')
    let ht_px_ratio_exp = readFromLocalStorage('ht_px_ratio_exp')
    let expired  = Math.floor(Date.now() / (1000 * 60)) - ht_px_ratio_exp
    if (ht_px_ratio_s == undefined || ht_px_ratio_s == null ||  expired >= class_seq.length * 2){
      is_cal_ratio.current = true
    }
    if (ht_px_ratio_s != null || ht_px_ratio_s != undefined){
        ht_px_ratio.current = ht_px_ratio_s
    }



  }, [currentPose])


  const closeConnection = ()=>{
    if (ws.current){
      ws.current.close()
      console.log('Connection closed')
   
    }
  }


  const captureFrame = async() => {
    // Ensure video and canvas refs are valid

    if (videoRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

      // Convert canvas image to base64
      const imageData = canvas.toDataURL('image/jpeg');
      // console.log(imageData)

      // Send frame data to server

      if ((ws.current.readyState === WebSocket.OPEN)  && (isStart.current)){
        ws.current.send(JSON.stringify({frameData: imageData,
                                        draw_bar: true,
                                        is_cal_ratio: is_cal_ratio.current,
                                        height: height.current,
                                        ht_px_ratio: ht_px_ratio.current,
                                        cal_angle: true
                                        }));
        // console.log('frame send to sever')
       
      }else{
        setResponseFrame(imageData)
      
      } 
      
    }
    
  };
  
 
  useEffect(() => {
    const interval = setInterval(() => {
      captureFrame()
    //  console.log('Interval is running')
     
    }, 400); 

    return () => clearInterval(interval); 
  }, []); 


  

  useEffect(() => {
    // Set up the interval
    isStartHandler(true)
    settargettimer( new Date(new Date().getTime() + 120000).toISOString())
  
    const intervalId = setInterval(() => {
        count.current = count.current + 1
        changeCurrentPose(count.current)
        settargettimer( new Date(new Date().getTime() + 120000).toISOString())
        isStartHandler(true)
        

    }, 120000);

    return () => clearInterval(intervalId);
}, [class_seq]);


  return (
    <>
  
  <SideBar allPose={allpose} currentPose={currentPose} setcurrentPose={setcurrentPose} tabvalue={tabvalue} settabvalue={settabvalue} class_seq={class_seq} setclass_seq={setclass_seq} setallpose={allposeupdater} allchoices={allchoices} />
  <Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
   { tabvalue ==- 0 && class_seq && class_seq.length > 0 && 
  (<CircularTimer targetTime={targettimer}/>)}
  {currentPose}
    <video ref={videoRef} style={{ display: 'none' }} width="100%" height="auto" autoPlay></video>
    <canvas ref={canvasRef} style={{ display: 'none' }} width={720} height={1080} />
    
    <Grid container spacing={2} direction="row" justifyContent={'right'} alignItems="right">
      {/* FrameViewer */}
      <Grid item xs={12} sm= {8} >
        <FrameViewer frame={responseFrame} />

        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {isPermission ? (
            <Grid item>
              <Typography color="error">Please grant permission to access your camera.</Typography>
            </Grid>
          ) : (
            <>
              {tabvalue === 1 && (
                <>
                  <Grid item>
                    {!isStart.current ? (
                      <Button onClick={() => isStartHandler(true)} variant="contained">
                        Start
                      </Button>
                    ) : (
                      <>
                        <Button
                          onClick={() => isStartHandler(false)}
                          style={{ marginLeft: '10px' }}
                          variant="outlined"
                          color="error"
                        >
                          Stop
                        </Button>
                        {allpose && allpose.yoga_posename && allpose.yoga_posename.includes(currentPose) && (
                          <Button
                            onClick={next_Pose_handler}
                            style={{ marginLeft: '10px' }}
                            variant="outlined"
                            color="success"
                          >
                            Next Pose
                          </Button>
                        )}
                      </>
                    )}
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>


      {/* ImageViewer */}
      <Grid item xs={12} sm = {4}>
        <ImageViewer frame={all_pose_image[currentPose]} text={currentPose} />
      </Grid>
    </Grid>
    <PopupModel  setcurrentPose={setcurrentPose} next_poses= {nextposes}/>
   
     <SpeedAngle  angle = {angle} speed = {speed}/>
   </Content>
</>
  );
};

export default SidebarComponent;
